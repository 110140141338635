import React from 'react';

export interface ProjectStackProps {
    type: string;
    stack: string[];
    repository?: string;
    website?: string;
}

export const ProjectStack: React.FC<ProjectStackProps> = ({
    type,
    stack,
    repository,
    website,
}) => {
    return (
        <div className="flex items-start my-12 text-sm md:text-base">
            <div className="w-1/4">
                <div className="font-bold uppercase text-sm mb-4">Type</div>
                <div>{type}</div>
            </div>
            <div className="w-1/4">
                <div className="font-bold uppercase text-sm mb-4">Stack</div>
                <div>
                    {stack.map((item, index) => (
                        <div key={`stack-${index}`} className="block">
                            {item}
                        </div>
                    ))}
                </div>
            </div>
            {repository && (
                <div className="w-1/4">
                    <div className="font-bold uppercase text-sm mb-4">Code</div>
                    <div>
                        <a
                            className="text-primary-500 hover:underline"
                            href={repository}
                        >
                            Repository
                        </a>
                    </div>
                </div>
            )}
            {website ? (
                <div className="w-1/4">
                    <div className="font-bold uppercase text-sm mb-4">Live</div>
                    <div>
                        <a
                            className="text-primary-500 hover:underline"
                            href={website}
                        >
                            View Site
                        </a>
                    </div>
                </div>
            ) : (
                <div className="w-1/4">
                    <div className="font-bold uppercase text-sm mb-4">Live</div>
                    <div className="text-primary-300">In Progress</div>
                </div>
            )}
        </div>
    );
};

export default ProjectStack;
