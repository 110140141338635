import React, { Fragment } from 'react';

import { Popover, Transition } from '@headlessui/react';

import { Logo } from './Logo';
import { MenuIcon } from './MenuIcon';
import { CloseIcon } from './CloseIcon';

export const links = [
    { href: '/', text: 'Home' },
    { href: '/#projects', text: 'Projects' },
    { href: '/#about', text: 'About' },
    { href: '/#skills', text: 'Skills' },
    { href: '/#contact', text: 'Contact' },
];

export interface NavbarProps {}

export const Navbar: React.FC<NavbarProps> = () => {
    return (
        <Popover as="div" className="">
            <div className="relative mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                <nav
                    className="relative flex items-center justify-between sm:h-10"
                    aria-label="Global"
                >
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                        <div className="flex items-center justify-between w-full md:w-auto">
                            <a href="/">
                                <Logo className="h-8 w-auto sm:h-10" />
                            </a>
                            <div className="-mr-2 flex items-center md:hidden">
                                <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <MenuIcon className="h-6 w-6" />
                                </Popover.Button>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8 text-secondary-300">
                        {links.map(item => (
                            <a
                                key={item.text}
                                href={item.href}
                                className="font-medium transition-colors duration-300 hover:text-primary-500"
                            >
                                {item.text}
                            </a>
                        ))}
                    </div>
                    <div>
                        <a
                            className="hidden lg:block border border-primary-400 hover:bg-primary-400 text-white hover:shadow-lg rounded-full px-9 py-3 font-bold text-base leading-6"
                            href="/cv.pdf"
                        >
                            Download CV
                        </a>
                    </div>
                </nav>
            </div>

            <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="px-5 pt-4 flex items-center justify-between">
                            <div>
                                <Logo className="h-8 w-auto sm:h-10" />
                            </div>
                            <div className="-mr-2">
                                <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                    <span className="sr-only">
                                        Close main menu
                                    </span>
                                    <CloseIcon className="h-6 w-6" />
                                </Popover.Button>
                            </div>
                        </div>
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {links.map(item => (
                                <a
                                    key={item.text}
                                    href={item.href}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                >
                                    {item.text}
                                </a>
                            ))}
                        </div>
                        <a
                            href="/cv.pdf"
                            className="block w-full px-5 py-3 text-center font-medium text-primary-600 bg-gray-50 hover:bg-gray-100"
                        >
                            Download CV
                        </a>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default Navbar;
