import React from 'react';

import { Header } from './Header';

export interface SectionProps {
    id?: string;
    header?: string;
    children?: any;
    className?: string;
}

export const Section: React.FC<SectionProps> = ({
    id,
    header,
    children,
    className = '',
}) => {
    return (
        <section
            id={id}
            className={`py-24 md:py-32 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ${className}`}
        >
            <div className="mx-auto max-w-7xl">
                {header && <Header>{header}</Header>}
                {children}
            </div>
        </section>
    );
};

export default Section;
