import React from 'react';

import { Logo } from './Logo';

const links = [
    { text: 'Git Repos', href: 'https://git.bjasulan.com/explore/repos' },
    { text: 'My CV', href: '/cv.pdf' },
];

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = props => {
    return (
        <footer className="pb-16">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-between">
                <a className="inline-block" href="/">
                    <Logo />
                </a>
                <ul className="justify-self-end flex items-center -mx-4">
                    {links.map((link, index) => (
                        <li
                            className="inline-block ml-2"
                            key={`footer-${index}`}
                        >
                            <a
                                className="inline-block py-2 border-b-2 border-transparent hover:border-white px-4"
                                href={link.href}
                            >
                                {link.text}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
