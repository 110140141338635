import React from 'react';

import { Section } from './Section';

export interface ContactProps {}

export const Contact: React.FC<ContactProps> = () => {
    return (
        <Section id="contact" header="Let's Build Something Together">
            <div className="text-lg text-secondary-400 text-center -mt-8">
                If you're looking for a developer, have a question, or just want
                to chat, feel free to reach out.
            </div>
            <div className="text-lg text-white text-center mt-6">
                <a
                    className="inline-block relative group font-bold pb-4 border-b-2 border-white transition-colors ease-in-out delay-150 duratin-300 hover:border-transparent"
                    href="mailto:bjasulan@pm.me"
                >
                    <span>bjasulan@pm.me</span>
                </a>
            </div>
        </Section>
    );
};

export default Contact;
