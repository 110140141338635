import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

export interface LogoProps {
    className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }) => {
    return (
        <>
            <span className="sr-only">Jasulan</span>
            <StaticImage
                className="w-8 h-8 md:w-10 md:h-10 opacity-90"
                src="../images/icon.png"
                alt=""
            />
            <span className="text-4xl hidden">
                <span className="text-secondary-700">[</span>
                <span className="text-primary-500 font-bold">JB</span>
                <span className="text-secondary-700">]</span>
            </span>
        </>
    );
};

export default Logo;
