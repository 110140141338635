import React from 'react';

export interface ProjectsCloudProps {
    // projects: { link: string; title: string }[];
    active?: { link: string; title: string };
}

export const projects = [
    { link: '/ecomsaas', title: 'Ecommerce SAAS' },
    { link: '/blogideas', title: 'BlogIdeas' },
    { link: '/designshuffle', title: 'DesignShuffle' },
];

export const ProjectsCloud: React.FC<ProjectsCloudProps> = ({
    // projects,
    active,
}) => {
    return (
        <nav className="flex items-center justify-center md:text-3xl text-secondary-600">
            {projects.map((project, index) => (
                <a
                    key={`p-${index}`}
                    className={`${
                        active?.link === project.link && 'text-secondary-100'
                    } inline-block px-2 md:px-4 mx-2 hover:text-secondary-100`}
                    href={project.link}
                >
                    {project.title}
                </a>
            ))}
        </nav>
    );
};

export default ProjectsCloud;
