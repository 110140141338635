import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export interface ProjectHeroProps {
    url?: string;
    title: string;
    subtitle?: any;
    image: any;
    description?: string;
    children?: any;
}

export const ProjectHero: React.FC<ProjectHeroProps> = ({
    url,
    title,
    subtitle,
    image,
    description,
    children,
}) => {
    return (
        <div className="group md:flex items-center bg-secondary-800 shadow-2xl lg:rounded-xl my-16">
            <div className="md:w-1/2 px-10 py-16">
                <div className="text-primary-400 font-bold mb-4 text-md">
                    {subtitle}
                </div>
                <h2 className="text-white text-3xl font-bold tracking-wide">
                    {title}
                </h2>
                <div className="mt-8 text-secondary-300">
                    {description || children}
                </div>
                <div className="mt-5 flex items-center">
                    <a
                        className="tracking-wide border-b-2 transition-colors duration-300 border-transparent font-bold leading-6 group-hover:border-white text-white transition-colors duration-300 hover:text-primary-500 py-3"
                        href={url}
                    >
                        Read More →
                    </a>
                </div>
            </div>
            <div className="md:w-1/2 bg-gradient-to-b from-cyan-500 to-primary-500 lg:rounded-r-xl flex items-center justify-center overflow-hidden">
                <div className="w-full relative h-[26rem] overflow-hidden text-center lg:rounded-b-xl transition ease-in-out delay-150 group-hover:-translate-y-1 group-hover:scale-110 duration-300">
                    {image}
                </div>
            </div>
        </div>
    );
};

export default ProjectHero;
