import React from 'react';

export interface HeaderProps {
    text?: string;
    align?: 'left' | 'center' | 'right';
    children?: any;
}

export const Header: React.FC<HeaderProps> = ({
    text,
    align = 'center',
    children,
}) => {
    const classNames = ['text-white text-4xl md:text-5xl font-bold mb-16'];

    if (align) {
        classNames.push(`text-${align}`);
    }

    return <h2 className={classNames.join(' ')}>{text || children}</h2>;
};

export default Header;
